import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0,1,2) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'HCAT',
    lpAddresses: {
      97: '0xb88af3f097e12e6509bc47ff3654ba7cc0716ac0',
      250: '0xb88af3f097e12e6509bc47ff3654ba7cc0716ac0',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
 
 
  {
    pid: 1,
    lpSymbol: 'HCAT-BNB LP',
    lpAddresses: {
      97: '',
      250: '0x3fe0e571883d373efda4eccb286972d76ad00424',
    },
    token: tokens.hcat,
    quoteToken: tokens.wbnb,
  },
  
  
  
  
  
  
  
   
	  
	
// finished last  2  
    

]

export default farms
