import { ChainId, Token } from '@pancakeswap/sdk'

export const CAKE: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
}
export const BUSD: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    6,
    'USDC',
    'Fantom USDC',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
  ),
}
export const WBNB = new Token(ChainId.MAINNET, '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83', 18, 'WFTM', 'Wrapped FTM')
export const DAI = new Token(ChainId.MAINNET, '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', 18, 'DAI', 'Dai Stablecoin')
export const USDT = new Token(ChainId.MAINNET, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'Tether USD')
export const BTCB = new Token(ChainId.MAINNET, '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18, 'BTCB', 'Binance BTC')
export const UST = new Token(
  ChainId.MAINNET,
  '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
  18,
  'UST',
  'Wrapped UST Token',
)
export const ETH = new Token(
  ChainId.MAINNET,
  '0x74b23882a30290451A17c44f4F05243b6b58C76d',
  18,
  'wETH',
  'Binance-Peg Ethereum Token',
)
export const USDC = new Token(
  ChainId.MAINNET,
  '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
  6,
  'USDC',
  'Fantom USDC Coin',
)




const tokens = {
  ftm: {
    symbol: 'FTM',
    projectLink: 'https://www.binance.com/',
  },

  how: {
    symbol: 'HOW',
    address: {
      250: '0xdAa64420e769fae36CCaA78e26024FE9f583e9D8',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://Howswapcoin.io/',
  },
  
  hcat: {
    symbol: 'HCAT',
    address: {
      250: '0xb88af3f097e12e6509bc47ff3654ba7cc0716ac0',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://Howswapcoin.io/',
  },
  
  
  
// ***************************************** default token   ********************************** 
 
		cake: {
		symbol: 'CAKE',
		address: {
		250: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
		97: '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
		},
		decimals: 18,
		projectLink: 'https://pancakeswap.finance/',
		},
		
		eth: {
		symbol: 'wETH',
		address: {
		250: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
		97: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
		},
		decimals: 18,
		projectLink: 'https://ethereum.org/en/',
		},
		
		btcb: {
		symbol: 'BTCB',
		address: {
		250: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
		97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
		},
		decimals: 18,
		projectLink: 'https://bitcoin.org/',
		},
		
		sol: {
		symbol: 'SOL',
		address: {
		250: '0x570A5D26f7765Ecb712C0924E4De545B89fD43dF',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://solana.com/',
		},
		
		wftm: {
		symbol: 'wFTM',
		address: {
		250: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
		97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
		},
		decimals: 18,
		projectLink: 'https://pancakeswap.finance/',
		},
 
		wbnb: {
		symbol: 'wFTM',
		address: {
		250: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
		97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
		},
		decimals: 18,
		projectLink: 'https://pancakeswap.finance/',
		},

		busd: {
		symbol: 'USDC',
		address: {
		250: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
		97: '',
		},
		decimals: 6,
		projectLink: 'https://www.paxos.com/busd/',
		},

		syrup: {
		symbol: 'SYRUP',
		address: {
		250: '0x89b51a235fe6439cec719e8b12e69f8cf368a249',
		97: '0x89b51a235fe6439cec719e8b12e69f8cf368a249',
		},
		decimals: 18,
		projectLink: 'https://pancakeswap.finance/',
		},

		usdt: {
		symbol: 'USDT',
		address: {
		250: '0x55d398326f99059fF775485246999027B3197955',
		97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
		},
		decimals: 18,
		projectLink: 'https://tether.to/',
		},

		kalm: {
		symbol: 'KALM',
		address: {
		250: '0x4BA0057f784858a48fe351445C672FF2a3d43515',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://kalmar.io/',
		},

		hotcross: {
		symbol: 'HOTCROSS',
		address: {
		250: '0x4FA7163E153419E0E1064e418dd7A99314Ed27b6',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://www.hotcross.com/',
		},

		hzn: {
		symbol: 'HZN',
		address: {
		250: '0xC0eFf7749b125444953ef89682201Fb8c6A917CD',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://horizonprotocol.com/',
		},

		belt: {
		symbol: 'BELT',
		address: {
		250: '0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://beta.belt.fi/',
		},

		watch: {
		symbol: 'WATCH',
		address: {
		250: '0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://yieldwatch.net/',
		},

		bry: {
		symbol: 'BRY',
		address: {
		250: '0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://berrydata.co/',
		},

		wsote: {
		symbol: 'wSOTE',
		address: {
		250: '0x541E619858737031A1244A5d0Cd47E5ef480342c',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://soteria.finance/#/',
		},

		helmet: {
		symbol: 'Helmet',
		address: {
		250: '0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://www.helmet.insure/',
		},

		ten: {
		symbol: 'TEN',
		address: {
		250: '0xdFF8cb622790b7F92686c722b02CaB55592f152C',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://www.tenet.farm/',
		},

		ditto: {
		symbol: 'DITTO',
		address: {
		250: '0x233d91A0713155003fc4DcE0AFa871b508B3B715',
		97: '',
		},
		decimals: 9,
		projectLink: 'https://ditto.money/',
		},

		blink: {
		symbol: 'BLINK',
		address: {
		250: '0x63870A18B6e42b01Ef1Ad8A2302ef50B7132054F',
		97: '',
		},
		decimals: 6,
		projectLink: 'https://blink.wink.org',
		},

		qsd: {
		symbol: 'QSD',
		address: {
		250: '0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://chemix.io/home',
		},

		bondly: {
		symbol: 'BONDLY',
		address: {
		250: '0x96058f8C3e16576D9BD68766f3836d9A33158f89',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://www.bondly.finance/',
		},

		safemoon: {
		symbol: 'SAFEMOON',
		address: {
		250: '0x8076C74C5e3F5852037F31Ff0093Eeb8c8ADd8D3',
		97: '',
		},
		decimals: 9,
		projectLink: 'https://safemoon.net/',
		},
  
}


export default tokens


