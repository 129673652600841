import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
   
	
		
    {
		label: t('FTM'),
		icon: 'Link2Icon',
		 
		
		
		items: [
		   
			{
			label: t('BSC'),
			href: 'https://howswap.com/',
			},
				
		],},
		
		
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: '/swap?outputCurrency=0x04068da6c83afcfa0e13ba15a6696662335d5b75',
      },
      {
        label: t('Liquidity'),
        href: '/pool',

      },
    ],
  },
 
 
  {
				label: t('Docs'),
				icon: 'InfoIcon',
				href: 'https://docs.howswap.com/',
				}, 
				
  
  

		
		
 
  
				
				
				
				
				 
				
				 
				
				
				
				
		
  
       
 
	
	
	
	
	

]

export default config
