import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [

  {
    sousId: 0,
    stakingToken: tokens.hcat,
    earningToken: tokens.hcat,
    contractAddress: {
      97: '0xaf970baf55218a308b2a2c61bbae5a2dcd5481d8',
      250: '0xaf970baf55218a308b2a2c61bbae5a2dcd5481d8',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '20',
    sortOrder: 1,
    isFinished: false,
  },
  
  
  
]

export default pools
